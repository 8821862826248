<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

delete vSelect.props.multiple
delete vSelect.props.clearable
delete vSelect.props.closeOnSelect
delete vSelect.props.appendToBody

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement =>
      createElement('a-icon', { props: { type: 'close' } })
  },
  OpenIndicator: {
    render: createElement =>
      createElement('a-icon', { props: { type: 'down' } })
  }
})

const X_MORE_ELEMENT_WIDTH = 75
const WRAPPER_PADDING = 2 * 10
const ITEM_MARGIN = 2 * 2

// issue: https://github.com/sagalbot/vue-select/issues/1354
// vSelect.props.reduce.default = item => item.value

export default {
  extends: vSelect,
  props: {
    mode: {
      type: String,
      default: 'default'
    },
    deselectFromDropdown: {
      type: Boolean,
      default: true
    },
    allowClear: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    showOptionsInOneLine: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    multiple() {
      return this.mode === 'multiple'
    },
    clearable() {
      return this.allowClear
    },
    closeOnSelect() {
      return !this.multiple
    },
    inputElement() {
      return this.$refs.selectedOptions
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.showOptionsInOneLine) {
          this.hideElements()
        }
      }
    }
  },
  mounted() {
    if (this.multiple && !this.showOptionsInOneLine) {
      this.addClassWrapElements()
    }
  },
  methods: {
    addClassWrapElements() {
      this.inputElement.classList.add('wrap-elements')
    },
    clearSelection() {
      const value = this.multiple ? [] : undefined
      if (value === undefined) {
        // Vue select has to manage value
        this.$data._value = value
      }
      this.$emit('input', value)
      this.$emit('change', value)
      this.removeXMoreElement()
    },
    // Override updateValue method in vue-select
    updateValue(value) {
      if (typeof this.value === 'undefined') {
        this.$data._value = value
      }
      if (value !== null) {
        value = Array.isArray(value)
          ? value.map(val => this.reduce(val))
          : this.reduce(value)
      }
      this.$emit('input', value)
      this.$emit('change', value)

      // If show options in one line props
      // - hide elements which don't fit
      if (this.showOptionsInOneLine) {
        this.hideElements()
      }
    },
    removeXMoreElement() {
      const elMore = this.inputElement.querySelector(
        '[data-select-more-element]'
      )

      elMore?.remove()
    },
    hideElements() {
      this.$nextTick(() => {
        this.removeXMoreElement()
        this.inputElement.style.flexWrap = 'nowrap'

        const selected = this.inputElement.querySelectorAll('.vs__selected')

        for (const child of selected) {
          child.style.display = 'none'
        }

        let numberRemoveElement = 0
        let inputWidth =
          this.inputElement.offsetWidth - X_MORE_ELEMENT_WIDTH - WRAPPER_PADDING

        for (const child of selected) {
          child.style.display = 'flex'
          const childWidth = child.offsetWidth

          if (childWidth > inputWidth) {
            numberRemoveElement++
            child.style.display = 'none'
          }

          inputWidth = inputWidth - childWidth - ITEM_MARGIN
        }
        if (numberRemoveElement) {
          this.addXMoreElement(numberRemoveElement)
        }
      })
    },
    addXMoreElement(number) {
      const span = document.createElement('span')

      span.classList.add('x-more-element')
      span.innerHTML = `+ ${number} more`

      const att = document.createAttribute('data-select-more-element')
      att.value = null
      span.setAttributeNode(att)

      this.inputElement.appendChild(span)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  line-height: 20px;

  &.vs--loading {
    .vs__selected {
      display: none;
    }
  }
}

::v-deep .x-more-element {
  padding-top: 10px;
  width: 75px;
  color: $grey;
  flex-shrink: 0;
}

.vs__search::placeholder {
  color: $neutral-5;
  font-size: 15px;
  line-height: 20px;
}

.has-error .vs__dropdown-toggle {
  border-color: $danger;
}

.vs--open .vs__dropdown-toggle,
.vs__dropdown-toggle {
  border: 1px solid $neutral-3;
  min-height: 40px;
  border-radius: 12px;
  background-color: $white;
}

.vs--open .vs__selected {
  position: relative;
}
.vs--disabled {
  .vs__dropdown-toggle,
  .vs__search {
    background: $neutral-1;
  }
}

.vs__actions {
  padding: 3px 20px 0 3px;

  .anticon {
    opacity: 0.5;

    ::v-deep svg {
      width: 12px;
      height: 12px;
    }
  }
}

.vs__deselect {
  margin-left: 10px;
}

.vs__selected-options {
  padding: 0 10px;
  background-color: unset;
  flex-wrap: nowrap;
}

.vs__selected {
  color: $primary;
  white-space: nowrap;
}

.vs--multiple {
  .vs__selected {
    display: flex;
    flex-shrink: 0;

    background-color: $neutral-2;
    border: unset;
    color: $neutral-8;

    padding: 4px 8px;
  }

  .vs__selected-options.wrap-elements {
    flex-wrap: wrap !important;
  }
}
.vs__dropdown-option {
  padding: 5px 20px;
  transition: background 0.3s ease;
  color: $neutral-5;
  font-size: 15px;
  line-height: 26px;
  font-family: Inter, sans-serif;
}

.vs__dropdown-option--highlight,
.vs__dropdown-option--selected,
.vs__dropdown-option:hover,
.vs__dropdown-option:active,
.vs__dropdown-option:focus {
  background-color: $neutral-2;
}

.vs__dropdown-option--selected {
  font-weight: 600;
}

.vs__dropdown-menu {
  margin-top: 2px;
  border-radius: 10px;
  max-height: 250px;
  padding: 0;
  border: unset;
}

.v-select .vs__clear {
  position: absolute;
  right: 5px;
  left: unset;
}
</style>
