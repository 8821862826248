<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view v-if="$route.meta.public" />
      <div v-else>
        <transition name="fade-up">
          <router-view v-if="resourcesLoaded" />
          <Loader v-else />
        </transition>
      </div>
    </a-config-provider>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, watch } from 'vue'

import { useBoardingRotaNotification } from '@/composables/useBoardingRotaNotification'
import { useModal } from '@/composables/useModal'
import { usePermissions } from '@/composables/usePermissions'
import VersionChecker from '@/mixins/VersionChecker.vue'
import store from '@/store'

let modalVisibilityObserver

const { isStaff, isSuperUser, userInfo, userProfiles } = usePermissions()

const { checkStatus, setTaskId, createInfoNotification } =
  useBoardingRotaNotification()

const { addModalVisibilityListener, removeModalVisibilityListener } = useModal()

const setSafeMode = () => {
  if (isSuperUser.value && userProfiles.value.length >= 1) {
    window.localStorage.setItem('safeModeDisabled', true)
    store.commit('setSafeModeDisabled', true)
    return
  }

  if (isStaff.value && userProfiles.value.length > 1) {
    const safeModeDisabled =
      window.localStorage.getItem('safeModeDisabled') === 'true'

    window.localStorage.setItem('safeModeDisabled', safeModeDisabled)
    store.commit('setSafeModeDisabled', safeModeDisabled)
    return
  }

  window.localStorage.removeItem('safeModeDisabled')
}

watch(
  () => userInfo.value,
  value => {
    if (value.id) setSafeMode()
  }
)

onMounted(() => {
  const boardingRotaRequestId = localStorage.getItem('boardingRotaRequestId')

  if (boardingRotaRequestId) {
    setTaskId(Number(boardingRotaRequestId))
    createInfoNotification()
    checkStatus()
  }

  modalVisibilityObserver = addModalVisibilityListener()

  if (userInfo.value.id) store.dispatch('fetchCommonData')
})

onUnmounted(() => {
  removeModalVisibilityListener(modalVisibilityObserver)
})
</script>

<script>
import moment from 'moment'
import 'moment/locale/en-gb'
import en_GB from 'ant-design-vue/lib/locale-provider/en_GB'
import { every } from 'lodash'

import Loader from '@/components/common/Loader.vue'

moment.locale('en-gb')

export default {
  components: {
    Loader
  },
  mixins: [VersionChecker],
  data() {
    return {
      locale: en_GB
    }
  },
  computed: {
    resourcesLoaded() {
      return every([
        this.userInfo.id,
        this.$store.state.aims.length,
        this.$store.state.grades.length,
        this.$store.state.quarters.length,
        this.$store.state.semesters.length,
        this.$store.state.years.length
      ])
    }
  },
  async created() {
    if (window.localStorage.getItem('token')) {
      await this.$store.dispatch('fetchUserInfo')
    }
  }
}
</script>
